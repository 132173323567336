import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import store from "./store/Store";
import router from "./router/Router";
import "./assets/scss/style.scss";
import "./assets/css/main.css";
import "./assets/css/venueBook.css";
import App from "./App.vue";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";

import TypeaheadAutocomplete from "typeahead-autocomplete";

// i18n
import i18n from './i18n/i18n.js'
import "vue-hljs/dist/style.css";
import  "./interceptor.js";


import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import VueCookies from 'vue-cookies'

import LottieVuePlayer from '@lottiefiles/vue-lottie-player';
 
Vue.use(LottieVuePlayer);

// Import the functions you need from the SDKs you need
/* import { initializeApp } from "firebase/app";

import { getMessaging, getToken ,onMessage } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyDvKp2aMY11CTVBOdGeoF2fYq2T3pe0Hc0",
  authDomain: "venuebook-efdc6.firebaseapp.com",
  projectId: "venuebook-efdc6",
  storageBucket: "venuebook-efdc6.appspot.com",
  messagingSenderId: "234048649688",
  appId: "1:234048649688:web:58a0bb45f9c6e9c6af9aad",
  measurementId: "G-9BWTEM3NFY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessaging();

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});

getToken(messaging, { vapidKey: 'BNgWpsU-STi0V_kNkD8klS9wRRG-SDuyGMYkt-Yw-4dFZAtVj1EMOu5R21Lv6KIi_ZqazAU9v2pdpjYIIZD7hP8' }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    console.log('Token is : ' +currentToken);
    // ...
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});

Vue.use(app); */
Vue.use(VueSweetalert2);

Vue.use(VueFeather);
Vue.use(VueCookies, { expires: '1d'})

Vue.config.productionTip = false;

import vueHljs from "vue-hljs";
import hljs from "highlight.js";

//use
Vue.use(vueHljs, { hljs });
Vue.use(InstantSearch);
Vue.use(TypeaheadAutocomplete);
new Vue({ store, router,i18n, render: (h) => h(App),scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  } }).$mount("#app");
