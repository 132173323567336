<template>
  <div id="main-wrapper">
     <router-view />
  </div>
</template>

<script>

  
export default {
  name: 'App',
  components: {
    
  },
  created() {
    this.$root.$refs.app = this;
   
  },
  methods: {
    showToast(variant, msg) {
      this.$bvToast.toast(msg, {
        title: process.env.VUE_APP_NAME,
        autoHideDelay: 1000,
        solid: true,
        variant: variant,
      });
    },
    showLoader(i) {
      if (i == 1) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
  },
}
</script>

<style>

</style>
