import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "en", // set default locale
  fallbackLocale: "en",
  silentTranslationWarn: true,
  messages: {
    en: {
      Starter: "Starter",
      "Classic Dashboard": "Classic Dashboard",
      "Analytical Dashboard": "Analytical Dashboard",
      "Ecommerce Dashboard": "Ecommerce Dashboard",
      "General Dashboard": "General Dashboard",
      Chat: "Chat",
      Email: "Email",
      Contact: "Contact",
      "Contact List": "Contact List",
      Ecommerce: "Ecommerce",
      Treeview: "Treeview",
      "Ui Elements": "Ui",
      Authentication: "Authentication",
      "Form Elements": "Form Elements",
      "Form Layouts": "Form Layouts",
      "Form Wizard": "Form Wizard",
      "Drag n Drop": "Drag n Drop",
      "Widget Apps": "Widget Apps",
      "Widget Data": "Widget Data",
      "Basic Table": "Basic Table",
      "Pagination Table": "Pagination Table",
      "Row Select Table": "Row Select Table",
      "Row Detail Table": "Row Detail Table",
      "Sort Table": "Sort Table",
      "Stacked Table": "Stacked Table",
      "Sticky Header-Column Table": "Sticky Header-Column Table",
      "Search Table": "Search Table",
      "Apex charts": "Apex charts",
      "Font Awesome Icons": "Font Awesome Icons",
      "Material Icons": "Material Icons",
      "Simpleline Icons": "Simpleline Icons",
      "Themify Icons": "Themify Icons",
      "Feather Icons": "Feather Icons",
      Dashboard: "Dashboard",
      Apps: "Apps",
      Forms: "Forms",
      Widgets: "Widgets",
      Pages: "Pages",
      Tables: "Tables"
    },
    fr: {
      Starter: "Kit de démarrage",
      "Classic Dashboard": "Tableau de bord classique",
      "Analytical Dashboard": "Tableau de bord analytique",
      "Ecommerce Dashboard": "Tableau de bord du commerce électronique",
      "General Dashboard": "Tableau de bord général",
      Chat: "Discuter",
      Email: "E-mail",
      Contact: "Contacter",
      "Contact List": "Liste de contacts",
      Ecommerce: "Commerce électronique",
      Treeview: "Treeview",
      "Ui Elements": "Éléments de l'interface utilisateur",
      Authentication: "Authentification",
      "Form Elements": "Éléments de formulaire",
      "Form Layouts": "Dispositions de formulaire",
      "Form Wizard": "Assistant de formulaire",
      "Drag n Drop": "Glisser et déposer",
      "Widget Apps": "Applications de widget",
      "Widget Data": "Données du widget",
      "Basic Table": "Table de base",
      "Pagination Table": "Table de pagination",
      "Row Select Table": "Tableau de sélection de ligne",
      "Row Detail Table": "Tableau de détail des lignes",
      "Sort Table": "Sort Table",
      "Stacked Table": "Table empilée",
      "Sticky Header-Column Table": "Table d'en-tête-colonne collante",
      "Search Table": "Table de recherche",
      "Apex charts": "Graphiques Apex",
      "Font Awesome Icons": "Icônes géniales de polices",
      "Material Icons": "Icônes matérielles",
      "Simpleline Icons": "Icônes Simpleline",
      "Themify Icons": "Icônes Themify",
      "Feather Icons": "Icônes de plumes",
      Dashboard: "Tableau de bord",
      Apps: "applications",
      Forms: "Formes",
      Widgets: "Widgets",
      Pages: "Des pages",
      Tables: "les tables"
    },
    ch: {
      Starter: "入门套件",
      "Classic Dashboard": "经典仪表板",
      "Analytical Dashboard": "分析仪表盘",
      "Ecommerce Dashboard": "电子商务仪表板",
      "General Dashboard": "通用仪表盘",
      Chat: "聊天",
      Email: "电子邮件",
      Contact: "接触",
      "Contact List": "联系人列表",
      Ecommerce: "电子商务",
      Treeview: "树视图",
      "Ui Elements": "Ui元素",
      Authentication: "验证",
      "Form Elements": "表单元素",
      "Form Layouts": "表单布局",
      "Form Wizard": "表单向导",
      "Drag n Drop": "拖曳n放下",
      "Widget Apps": "窗口小部件应用",
      "Widget Data": "小部件数据",
      "Basic Table": "基本表",
      "Pagination Table": "分页表",
      "Row Select Table": "行选择表",
      "Row Detail Table": "行明细表",
      "Sort Table": "排序表",
      "Stacked Table": "堆积表",
      "Sticky Header-Column Table": "粘页眉栏表",
      "Search Table": "搜索表",
      "Apex charts": "顶点图表",
      "Font Awesome Icons": "字体真棒图标",
      "Material Icons": "材质图标",
      "Simpleline Icons": "Simpleline图标",
      "Themify Icons": "主题图标",
      "Feather Icons": "羽毛图标",
      Dashboard: "仪表盘",
      Apps: "应用",
      Forms: "形式",
      Widgets: "小部件",
      Pages: "页数",
      Tables: "桌子"
    },
    gr: {
      Starter: "Starter-Kit",
      "Classic Dashboard": "Klassisches Dashboard",
      "Analytical Dashboard": "Analytisches Dashboard",
      "Ecommerce Dashboard": "E-Commerce-Dashboard",
      "General Dashboard": "Allgemeines Dashboard",
      Chat: "Plaudern",
      Email: "Email",
      Contact: "Kontakt",
      "Contact List": "Kontaktliste",
      Ecommerce: "E-Commerce",
      Treeview: "Baumsicht",
      "Ui Elements": "Ui-Elemente",
      Authentication: "Authentifizierung",
      "Form Elements": "Formularelemente",
      "Form Layouts": "Formularlayouts",
      "Form Wizard": "Formzauberer",
      "Drag n Drop": "Ziehen und loslassen",
      "Widget Apps": "Widget Apps",
      "Widget Data": "Widget-Daten",
      "Basic Table": "Grundtabelle",
      "Pagination Table": "Paginierungstabelle",
      "Row Select Table": "Zeilenauswahltabelle",
      "Row Detail Table": "Zeilendetailtabelle",
      "Sort Table": "Tabelle sortieren",
      "Stacked Table": "Gestapelter Tisch",
      "Sticky Header-Column Table": "Sticky Header-Column Table",
      "Search Table": "Suchtabelle",
      "Apex charts": "Apex-Diagramme",
      "Font Awesome Icons": "Font Awesome Icons",
      "Material Icons": "Materielle Symbole",
      "Simpleline Icons": "Einfache Symbole",
      "Themify Icons": "Themify Icons",
      "Feather Icons": "Federsymbole",
      Dashboard: "Instrumententafel",
      Apps: "Apps",
      Forms: "Formen",
      Widgets: "Widgets",
      Pages: "Seiten",
      Tables: "Tabellen"
    },
  },
});
