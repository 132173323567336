import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
import createPersistedState from "vuex-persistedstate";
import createMultiTabState from 'vuex-multi-tab-state';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Theme: "light", // theme can be light or dark
    LayoutType: "full-sidebar", // this can be full-sidebar, mini-sidebar
    Sidebar_drawer: false,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    navbarColor: "primary",
    logoColor: "white",
    user_fname: "",
    user_lname: "",
    setHorizontalLayout: false, // Horizontal layout
    Direction: "ltr", // possible value  rtl / ltr
    // Calendar Events
    offline_notification:0,
    edit_id:0,
    notificationClick:'',
    setKycSection:'',
    set_click_option:'',
    set_refresh_option:'',
    calendarEvents: [
      {
        title: "test",
        allDay: true,
        start: moment(),
        end: moment().add(1, "d"),
      },
    ],
    offline_calendar_booking:[],
    offline_booking_quotation:[],
    set_custom_amount_change:0,
    all_user_data:'',
    set_all_view_data:'',
    set_booking_option:'',
    set_income_option :'',
    set_income_expense_option:'',
    set_booking_header:'',
    set_taxation_option:'',
    set_user_data:'',
    user_app_logo:'',
    
  },
  mutations: {
    SET_THEME(state, payload) {
      state.Theme = payload;
    },
    SET_LAYOUT_TYPE(state, payload) {
      state.LayoutType = payload;
    },
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LOGO_COLOR(state, payload) {
      state.logoColor = payload;
    },
    SET_USER_FNAME(state, payload) {
      state.user_fname = payload;
    },
    SET_USER_LNAME(state, payload) {
      state.user_lname = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
    SET_DIRECTION(state, payload) {
      state.Direction = payload;
    },
    // Add Calendar event
    ADD_CALENDAR_EVENT(state, event) {
      const newEvent = Object.assign({}, event);
      state.calendarEvents.push(newEvent);
    },
    SET_OFFLINE_CHILDVENUE(state, payload) {
      state.offline_calendar_booking = payload;
    },
    SET_OFFLINE_BOOKING_QUOTATION(state, payload) {
      state.offline_booking_quotation = payload;
    },
    SET_NOTIFICATION(state, payload) {
      state.offline_notification = payload;
    },
    SET_EDIT_ID(state, payload) {
      state.edit_id = payload;
    },
    SET_NOTIFICATION_CLICK(state, payload) {
      state.notificationClick = payload;
    },
    SET_KYC_NOTIFICATION(state, payload) {
      state.setKycSection = payload;
    },
    SET_CLICK_OPTION(state, payload) {
      state.set_click_option = payload;
    }, 
    SET_REFRESH_OPTION(state, payload) {
      state.set_refresh_option = payload;
    },
    SET_CUSTOM_AMOUNT_CHANGE(state, payload) {
      state.set_custom_amount_change = payload;
    },
    ALL_USER_DATA(state, payload) {
      state.all_user_data = payload;
    },
    SET_VIEW_DETAILS(state, payload) {
      state.set_all_view_data = payload;
    },
    SET_BOOKING_DETAILS(state, payload) {
      state.set_booking_option = payload;
    },
    SET_INCOME_DETAILS(state, payload) {
      state.set_income_expense_option = payload;
    },
    SET_EXPENSE_INCOME_DETAILS(state, payload) {
      state.set_income_option = payload;
    }, 
    SET_BOOKING_HEADER(state, payload) {
      state.set_booking_header = payload;
    }, 
    SET_TAXATION_HEADER(state, payload) {
      state.set_taxation_option = payload;
    },
    SET_ADMIN_ID(state, payload) {
      state.set_admin_id = payload;
    },
    SET_USER_DATA(state, payload) {
      state.set_user_data = payload;
    },
    SET_APP_LOGO(state, payload) {
      state.user_app_logo = payload;
    }
  
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
    createMultiTabState(),
  ],
  actions: {
    setLayoutType({ commit }, width) {
      commit("SET_LAYOUT_TYPE", width);
    },
    //Calendar Action
    addCalendarEvent({ commit }, event) {
      commit("ADD_CALENDAR_EVENT", event);
    },
  },
  getters: {},
});
