import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/login",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "login",
          path: "login",
          component: () => import("@/views/authentication/BoxedLogin"),
        },
        {
          name: "verification",
          path: "verification",
          component: () => import("@/views/authentication/Verification_otp"),
        },
        {
          name: "Error",
          path: "error",
          component: () => import("@/views/authentication/Error"),
        },
        {
          name: "Forgot Password",
          path: "forgot_password",
          component: () => import("@/views/authentication/Forgot_password_email"),
        },
        {
          name: "Send mail",
          path: "send_link",
          component: () => import("@/views/authentication/Send_link_message"),
        },
        {
          name: "Contact us",
          path: "contact_us",
          component: () => import("@/views/Contact_us"),
        },
        {
          name: "Reset Password",
          path: "reset_password/:token",
          component: () => import("@/views/authentication/Set_new_p"),
        },
        {
          name: "account_verify",
          path: "account/verified/:token",
          component: () => import("@/views/authentication/account_verified"),
        },
        {
          name: "Expire Reset Password",
          path: "expire_link",
          component: () => import("@/views/authentication/ExpireForgotLink"),
        },
      ],
    },
    {
      path: "/dashboard",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "Manage Venues",
          path: "/venues",
          component: () => import("@/views/venues/Index"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "add parent child venue",
          path: "/venues/add-parent-child-venue",
          component: () => import("@/views/venues/Add_parent_child_venue"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Create Parent Venue",
          path: "/venues/add-parent-venue",
          component: () => import("@/views/venues/Add_parent_venue"),
          meta: {
            requiresAuth: true
          },

        },
        {
          name: "Edit Parent Venue",
          path: "/venues/edit-parent-venue/:id",
          component: () => import("@/views/venues/Edit_parent_venue"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "View Parent Venue",
          path: "/venues/view-parent-venue/:id",
          component: () => import("@/views/venues/View_parent_venue"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Child Venue",
          path: "/venues/child-venue/:id",
          component: () => import("@/views/venues/Child_venue_index"),
          meta: {
            requiresAuth: true
          },
        },
         {
          name: "Child Venue Add",
          path: "/venues/add-child-venue/:id",
          component: () => import("@/views/venues/Add_child_venue"),
          meta: {
            requiresAuth: true
          }, 
        },
        {
          name: "Child Venue Edit",
          path: "/venues/edit-child-venue/:id",
          component: () => import("@/views/venues/Edit_child_venue"),
          meta: {
            requiresAuth: true
          }, 
        },
        {
          name: "Child Venue Edit",
          path: "/venues/Child_venue/index/:id",
          component: () => import("@/views/venues/Child_venue/index"),
          meta: {
            requiresAuth: true
          }, 
        },{
          name: "Child Venue Edit",
          path: "/venues/new_edit_child_venue/index/:id",
          component: () => import("@/views/venues/Edit_child_venue/index"),
          meta: {
            requiresAuth: true
          }, 
        },
        {
          name: "Child Venue View",
          path: "/venues/view-child-venue/:id",
          component: () => import("@/views/venues/View_child_venue"),
          meta: {
            requiresAuth: true
          }, 
        },
        {
          name: "Dashboard",
          path: "/dashboard",
          component: () =>
            import("@/views/dashboards/dashboard_new"),
         //   import("@/views/dashboards/Dashboard"),
          meta: {
            requiresAuth: true
          },
        },{
          name: "dashboard",
          path: "/dashboard_new",
          component: () =>
            import("@/views/dashboards/dashboard_new"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Profile",
          path: "/profile",
          component: () => import("@/views/Profile/index"),
          meta: {
            requiresAuth: true
          },
        }, 
        /* View Vendor */
        {
          name: "Vendors",
          path: "/Manage_vendors",
          component: () => import("@/views/Vendor/Manage_vendors"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Vendors",
          path: "/admin_create_vendor",
          component: () => import("@/views/Vendor/Create_vendor"),
          meta: {
            requiresAuth: true
          },
        },
      /*   {
          name: "Vendors Venue List",
          path: "/Manage_vendors/parent_venue/:id",
          component: () => import("@/views/Vendor/vendor_child_venue"),
          meta: {
            requiresAuth: true
          },
        },  */
        {
          name: "Vendor Child Venue",
          path: "/Manage_vendors/parent_venue/child_venue/:id/:vendor_id",
          component: () => import("@/views/Vendor/vendor_child_venue"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Vendor Child Venue View",
          path: "/manage_vendors/parent_venue/child_venue/view/:vendor_id/:parent_id/:id",
          component: () => import("@/views/Vendor/view_vendor_child_venue"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "Vendor Details",
          path: "/vendor_details/:id",
          component: () => import("@/views/Vendor/vendor_detail"),
          meta: {
            requiresAuth: true 
          }, 
        },
        {
          name: "Vendor Parent",
          path: "/Manage_vendors/vendor_parent/:id",
          component: () => import("@/views/Vendor/vendor_parent"),
          meta: {
            requiresAuth: true 
          }, 
        },
        /* View vendor */

        /* Manage Vendor */
        {
          name: "Manage Vendors",
          path: "/admin_manage_booking",
          component: () => import("@/views/Vendor/admin_manage_booking"),
          meta: {
            requiresAuth: true 
          },
        },
        /* Manage Vendor */

        /* Bookings */
        {
          name: "Create booking",
          path: "/add-booking",
          component: () => import("@/views/Booking/OfflineBooking"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "My Booking",
          path: "/my-booking",
          component: () => import("@/views/Booking/all_booking"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "Booking Details",
          path: "/Booking/details/:id",
          component: () => import("@/views/Booking/booking_view"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "editbooking",
          path: "/edit-booking/:id/:token",
          component: () => import("@/views/Booking/editOfflineBooking"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "editbooking",
          path: "/draft-edit-booking/:id",
          component: () => import("@/views/Booking/editOfflineDraftBooking"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Booking Details",
          path: "/Booking/invoice/:id",
          component: () => import("@/views/Booking/invoice"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "Booking Details",
          path: "/Booking/final_invoice/:id",
          component: () => import("@/views/Booking/final_invoice"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "Manage Booking",
          path: "/statements",
          component: () => import("@/views/Booking/manage_booking"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "Manage Booking View",
          path: "/Booking/manage_booking_view/:id",
          component: () => import("@/views/Booking/manage_booking_view"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "Booking Draft",
          path: "/drafts-booking",
          component: () => import("@/views/Booking/Draft_Quotation/Draft_booking"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "Booking Draft details",
          path: "/Draft_Quotation/draft_details/:id",
          component: () => import("@/views/Booking/Draft_Quotation/draft_details"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "Booking Draft details",
          path: "/Draft_Quotation/view_details/:id",
          component: () => import("@/views/Booking/Draft_Quotation/view_details"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "Historical",
          path: "/historical_tab",
          component: () => import("@/views/Booking/History/history"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "Historical Details",
          path: "/history/history_details/:id",
          component: () => import("@/views/Booking/History/history_detail"),
          meta: {
            requiresAuth: true 
          },
        },

        /* Bookings */

        /* Calendar */
        {
          name: "Calendar Details",
          path: "/new_calender",
          component: () => import("@/views/Calendar/Calender"),
          meta: {
            requiresAuth: true 
          },
        },
        /* Calendar */

        /* Upload */
        {
          name: "Admin_Setting",
          path: "/Admin_Setting",
          component: () => import("@/views/Upload/Admin_Setting"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "Pincode Upload",
          path: "/Upload/pincode_manage",
          component: () => import("@/views/Upload/PincodeUpload"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "History Upload",
          path: "/Upload/history_upload",
          component: () => import("@/views/Upload/HistoryUpload"),
          meta: {
            requiresAuth: true 
          },
        },
        /* Upload */

        /* Customer */
        {
          name: "View Customer",
          path: "/my-customers",
          component: () => import("@/views/Customer/all_customer"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "View Customer",
          path: "/Customer/view/:id",
          component: () => import("@/views/Customer/view_customer"),
          meta: {
            requiresAuth: true 
          },
        },
        {
          name: "Manage Customer",
          path: "/my-customerss",
          component: () => import("@/views/Customer/manage_customer"),
          meta: {
            requiresAuth: true 
          },
        },

        /* Customer */

        /* Finance  */
        {
          name: "Finance module",
          path: "/finance_modules",
          component: () => import("@/views/Finance/invoice_payment/finance_modules"),
          meta: {
            requiresAuth: true
          }, 
        },
        {
          name: "Admin Finance invoice_list",
          path: "/vendor_invoice/invoice_list/:id/:id1/:id2",
          component: () => import("@/views/Finance/invoice_payment/vb/vbinvoice_lists"),
          meta: {
            requiresAuth: true
          }, 
        },
        {
          name: "Invoice",
          path: "/Finance/vb/invoice/:id",
          component: () => import("@/views/Finance/invoice_payment/vb/vbinvoice_list"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Invoice",
          path: "/Finance/invoice/:id",
          component: () => import("@/views/Finance/invoice_payment/invoice"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Admin Finance Taxation",
          path: "/finance/taxation",
          component: () => import("@/views/Finance/taxation/taxation"),
          meta: {
            requiresAuth: true
          }, 
        },
        {
          name: "Tax view",
          path: "/Finance/taxation/view_finance_list/:id",
          component: () => import("@/views/Finance/taxation/view_finance_list"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "TDS view",
          path: "/Finance/taxation/view_tds_list/:id/:id2",
          component: () => import("@/views/Finance/taxation/view_tds_list"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Satement Report ",
          path: "/finance/satement_report",
          component: () => import("@/views/Finance/Report/statements_report"),
          meta: {
            requiresAuth: true
          },
          children: [
            {
                path: '/finance/satement_report',
                name: 'epic',
                component: () => import("@/views/Finance/Report/revenue"),
                props: true
            },
            {
                path: '/finance/satement_report/income-expense',
                name: 'story',
                component: () => import("@/views/Finance/Report/income_expense"),
                props: true
            },
            {
                path: '/finance/satement_report/recept-payment',
                name: 'story',
                component: () => import("@/views/Finance/Report/receipt_payment"),
                props: true
            },
            {
                path: '/finance/satement_report/vb_report',
                name: 'story',
                component: () => import("@/views/Finance/Report/vb_report"),
                props: true
            } 
        ]
        }, 
        {
          name: "revenue_list",
          path: "/Report/revenue_list/:id/:id1",
          component: () => import("@/views/Finance/Report/revenue_list"),
          meta: {
            requiresAuth: true
          }, 
        },
        /*  {
          name: "Statements",
          path: "/Admin/satement_report",
          //component: () => import("@/views/Finance/Report/statements_report"),
          component: () => import("@/views/Finance/statements_report"),
          meta: {
            requiresAuth: true , 
          },
        },*/
        {
          name: "Income-Expense",
          path: "/finance/income-expense",
          component: () => import("@/views/Finance/index"),
          meta: {
            requiresAuth: true, 
          },
          children: [
          {
            path: '/finance/income-expense',
            name: 'story',
            component: () => import("@/views/Finance/Income/income_entry"),
            props: true
          },
          {
            path: '/finance/income/vb-receipt',
            name: 'vb-receipt',
            component: () => import("@/views/Finance/Income/vb_receipt"),
            props: true
          }
        ]
        },
        {
          name: "Finance Setting ",
          path: "/finance/setting",
         // component: () => import("@/views/Finance/satement_setting"),
          component: () => import("@/views/Upload/Admin_Setting"),
          meta: {
            requiresAuth: true
          },
        },
        /* Finance  */

        /* service provider */
        {
          name: "service provider ",
          path: "/service_provider",
          component: () => import("@/views/Serviceprovider/view_service_provider"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Manage provider ",
          path: "/manage_provider",
          component: () => import("@/views/Serviceprovider/Manage_provider"),
          meta: {
            requiresAuth: true
          },
        },
        /* Manage Proivder */

        /* Franchise */
        {
          name: "Manage Franchise ",
          path: "/view_franchise",
          component: () => import("@/views/Franchise/view_franchise"),
          meta: {
            requiresAuth: true
          },
        }, 
        {
          name: "Manage Franchise ",
          path: "/Manage_franchise",
          component: () => import("@/views/Franchise/Manage_franchise"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Revenuee ",
          path: "/Finance/all_revenue",
          component: () => import("@/views/Finance/Revenue/index"),
          meta: {
            requiresAuth: true
          },
        },
        /* {
          name: "Revenuee view ",
          path: "/Finance/list_revenue/:id",
          component: () => import("@/views/Finance/Revenue/view_all_revenue"),
          meta: {
            requiresAuth: true
          },
        }, */
        {
          name: "Revenuee view ",
          path: "/Finance/list_revenue/:id",
          component: () => import("@/views/Finance/Revenue/view_all_revenue"),
          meta: {
            requiresAuth: true
          },
          children: [
            {
                path: '/Finance/list_revenue/:id',
                name: 'epic',
                component: () => import("@/views/Finance/Revenue/tab/total_booked_revenue"),
                props: true
            },
            {
                path: '/revenue/event_pending/:id',
                name: 'epic',
                component: () => import("@/views/Finance/Revenue/tab/event_pending"),
                props: true
            },
            {
                path: '/revenue/event_completed/:id',
                name: 'epic',
                component: () => import("@/views/Finance/Revenue/tab/event_completed"),
                props: true
            },
            {
                path: '/revenue/total_invoice/:id',
                name: 'epic',
                component: () => import("@/views/Finance/Revenue/tab/total_invoice"),
                props: true
            },
            {
                path: '/revenue/vb_generated_invoice/:id',
                name: 'epic',
                component: () => import("@/views/Finance/Revenue/tab/vb_generated_invoice"),
                props: true
            },
            {
                path: '/revenue/vb_paid_invoice/:id',
                name: 'epic',
                component: () => import("@/views/Finance/Revenue/tab/vb_paid_invoice"),
                props: true
            },
            {
                path: '/revenue/vb_pending_invoice/:id',
                name: 'epic',
                component: () => import("@/views/Finance/Revenue/tab/vb_pending_invoice"),
                props: true
            },
        ]
        },
        {
          name: "sales_dashboard ",
          path: "/leads_dashboard",
          component: () => import("@/views/Sales/sales_dashboard"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Sales ",
          path: "/Sales",
          component: () => import("@/views/Sales/index"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Sales Details",
          path: "/Sales/view_sales_details/:id",
          component: () => import("@/views/Sales/view_sales_details"),
          meta: {
            requiresAuth: true
          },
        },
        {
          name: "Manage Sales ",
          path: "/Manage_Sales",
          component: () => import("@/views/Sales/manage_sale"),
          meta: {
            requiresAuth: true
          },
        },
      ],
    },
    {
      path: "*",
      redirect: '/dashboard',
      component: () => import("@/views/dashboards/Dashboard"),
      children: [
        {
          name: "Dashboard",
          path: "/dashboard",
          component: () =>
            import("@/views/dashboards/Dashboard"),
        },
      ],
    },
  ],
});

import NProgress from "nprogress";


router.beforeResolve((to, from, next) => {
document.title = process.env.VUE_APP_NAME;
  if (!to.name) {
    if (localStorage.getItem('authenticated')) {
       next();
    } else {

      localStorage.clear();
      next({ name: 'login' })
    }
  }
 
  if (to.matched.some(record => record.meta.AdminAuth)) {
    if(localStorage.getItem('authenticated'))
    {
      var role_type = JSON.parse(localStorage.getItem("loggedUserData")).role_type;
      if (role_type === 'admin') {
        next();
      } else {
        next({ name: 'Dashboard' })
      }
    }
   else
   {
    localStorage.clear();
      next({ name: 'login' })
   }
    }
    else  if (to.matched.some(record => record.meta.VendorAuth)) {
      if(localStorage.getItem('authenticated'))
      {
        var role_type_v = JSON.parse(localStorage.getItem("loggedUserData")).role_type;
        if (role_type_v === 'vendor') {
          next();
        } else {
          next({ name: 'Dashboard' })
        }
      }
     else
     {
      localStorage.clear();
        next({ name: 'login' })
     }
      }
 else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('authenticated')) {
      next();
    } else {
      localStorage.clear();
      next({ name: 'login' })
    }
  }
  else {
    if (localStorage.getItem('authenticated')) {
      if (to.name == 'account_verify') {
        next()
      } else {
        next({ name: 'Dashboard' })
      }

    }
    else {
      next()
    }
  } 


});


router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
